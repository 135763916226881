.CR-Container {
  display: flex;
  
  font-family: "inria";
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-width: 100%;
  flex-wrap: wrap; 
  justify-content: flex-start;
  align-content: center;
}


.CR-Map-Section {
  display: flex;
  padding: 0;
  font-family: "inria";
  flex-direction: column;
  align-items: center;
  margin: 1%;
  justify-content: space-evenly;
  background: linear-gradient(#b0c9df09, #e6646500);
  position: relative;
  height: 344px;
  width:  22.5%;
  max-height: fit-content;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  /* Box shadow */
  border-radius: 10px;
  /* Border radius */
}

.CR-Map-Section:hover {
  filter: saturate(1.42);
  border: solid 1px rgba(98, 98, 109, 0.247);
  background: linear-gradient(#b0c9df09, #dfcfb00e);
}

/* General styling for all items */
.CR-Link,
.CR-Cell,
.CR-Custom-Del-Item,
.CR-Del-Item,
.CR-Del-imgItem,
.CR-Custom-Edit-Item,
.CR-Edit-imgItem,
.CR-Edit-Item,
.CR-Attribute-Item,
.CR-imgItem,
.CR-Text-Item {
  width: 100%;
  border-radius: 11px;
  /* margin: 5px; */
  /* background-color: #16161605; */
  font-family: "inria";
  user-select: none;
  font-size: 1.1rem;
}

.CR-Cell{
  display: flex;
}

.CR-Link, .Button-Type1 {
  /* background: #e8d259; */
  color: "#111319";
  font-size: 1.1rem;
  
  width: 100%;
  height: 100%;
  z-index: 200;
  cursor: pointer;
}

.Button-Type2 {
  background: #0f141cf5;
  color: #ffffdf;
  padding: 6px 10px;
  margin: 5px;
  margin-bottom: 11px;
  cursor: pointer;
  display: flex;
  align-self: end;
  height: fit-content;
  width: fit-content;
  border-radius: 9px;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  justify-items: center;
  font-family: 'inria';
  justify-self: flex-end;
}

/* .CR-Link:hover {
  box-shadow: inset 2px 2px black;
  transform: scale(0.98);
  color: "#111319";
} */

.CR-Link:hover{
  transform: scale(0.99);
  box-shadow: inset 2px 2px #00000055, inset -2px -2px  #ffffff55;
  color: "#111319";
}

.Button-Type1:hover,.Button-Type2:hover{
  transform: scale(0.98);
  color: "#111319";
}


.a {
  color: #000;
  cursor: pointer;
  opacity: 0;
}

.Publisher {
  width: 102.5%;
  padding-right: 3px;
  margin-right: -10px;
  margin-left: -8px;
  height: fit-content;
  font-size: 1.1rem;
  opacity: 90%;
  text-align: right;
  color:  #e8d259;
  margin-top: -10px;
  border-radius: 0%;
  background-color: #0f141cf5;
}

/* Add specific styles for each item type if needed */
.CR-Link {
  text-decoration: none;
  cursor: pointer;
  color: rgb(190, 221, 226);
}


.CR-Custom-Del-Item,
.CR-Del-Item,
.CR-Del-imgItem {
  color: #dc3545;
  cursor: pointer;
  position: absolute;
  padding: 0px;
  right: 3px;
  top: 3px;
}

.CR-imgItem {
  width: 100px;
  border-radius: 15px;
  min-width: 100px;
}

.CR-hover-shimmer {
  top: 15px;
  position: absolute;
  border: 1px solid #1E90FF00;
  right: 0px;
  opacity: 92%;
  overflow: hidden;
}

.CR-hover-shimmer:hover {
  position: absolute;
  opacity: 98%;
  border: 1px solid #1E90FF;
  box-shadow: inset 2px 2px 4px #000000, inset -2px -2px 4px #ffffff55;
}

.CR-hover-shimmer::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; /* Start from the left */
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  background: linear-gradient(90deg, transparent, rgba(52, 55, 73, 0.4), transparent);
  animation: none; /* No animation by default */
  opacity: 0; /* Make it invisible by default */
  transition: opacity 0.5s ease; /* Smooth transition for the shimmer effect */
  mix-blend-mode: lighten;
}

.CR-hover-shimmer:hover::before {
  animation: shimmer 8s infinite; /* Adjust time as needed */
  opacity: 1; /* Make the shimmer visible on hover */
}


@keyframes shimmer {
  0% {
    left: -580%; /* Start from the left */
  }
  50% {
    left: 980%; /* Move to the right */
  }
  100% {
    left: -100%; /* Return to start */
  }
}

.CR-Custom-Edit-Item,
.CR-Edit-imgItem,
.CR-Edit-Item {
  color: #ffc107;
  background-color: none;
  cursor: pointer;
  position: absolute;
  padding: 0px;
  left: 3px;
  top: 3px;
}

.Bold-Title   {
  height: 100%;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  height: fit-content;
  vertical-align:middle;
  padding-top: .3rem;
  padding-bottom: .3rem;
  z-index: 200;
  font-family: 'inria';
  color: aliceblue;
  background-color: #1825319f;
  text-overflow: ellipsis;
  margin-top: -1.85rem;
  word-wrap: break-word;
}

.Ellipsis-Text {
  margin-top: -12px;
  display: block;
  /* overflow: hidden;
  text-overflow: ellipsis; */
 color: rgb(190, 221, 226);
  hyphens: auto;
  max-width: 330px;
  min-height: 5.5rem;
  height: 12.5rem;
  padding: 8px;
  max-height: 12.5rem;
  font-size: .8rem;
  text-align: justify;
  
}

.library-text {
  font-family: 'inria';
  color: rgb(190, 221, 226);
  margin-top: -12px;
  display: block;
  text-overflow: ellipsis;
  hyphens: auto;
  color: rgb(228, 228, 228);
  max-width: 330px;
  height: 11rem;
  font-size: 1.1rem;
  text-align: justify;
  padding:8px;
}



.Img-Midsize {
  width: 100%;
  /* max-width: 340px;
  max-height: 200px;*/
  min-height: 100%; 
  opacity: .84;
  border-radius: 11px;
  object-fit: cover;
  z-index: 100;
}

.Img-Large {
  width: 80vw;
  max-height: 400px;
  min-height: 400px;
  border-radius: 11px;
  object-fit: cover;
}

.CR-Attribute-Box {
  position: absolute;
  top: 1rem;
  z-index: 220;
  right: 1rem;
  display: inline-flex;  /* or inline-flex if you prefer */
  background-color: #17334e;
  color: #fff;
  padding: 4px 8px;
  
  font-weight: bold;
  color: #fff;
  border-color: linea;
  font-weight: bold;
  border-width: 3px;
  border-image: linear-gradient(to right, #8095e263 0%, #3a4fd500 100%) 1;
  border-radius: 6px;
  border-style: solid;
}

