.IM-Map-Container {

    height: 100%;
    width: 100%;
    position: relative;

  }

  .IM-Map-Section{
    left:0;
    top:0;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }

  .IM-Map{
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
  }

  .IM-addButton{
    position: absolute;
    left: 0;
    top:0;
  }