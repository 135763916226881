.DRW-Map-Container {
    display: flex;
    flex-direction: row; /* Switched to row layout */
    height: 100%;
    flex-wrap: wrap; 
  }

  .changeClass{
    color:green
  }

  .DRW-Map-Container{
    display: flex;
    font-family: "inria";
    flex-direction: row;
    /* Switched to row layout */
    width: 100%;
    max-width: 68vw;
    height: fit-content;
    flex-wrap: wrap; 
    justify-content: space-evenly;
    align-content: center;
  }

  .Image-Item{
    width: 340px;
    max-height: 340px;
    min-height: 340px;
    object-fit: scale-down;
    justify-items: center;
    align-items: center;
    padding: 0px;
    border-radius: 11px;
    background: unset;
  }